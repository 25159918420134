import React from "react"
import { Link } from "gatsby"
import NavBar from "./NavBar";

import { rhythm, scale } from "../utils/typography"
import Container from "react-bootstrap/Container"
import BlogList from "./BlogList"
import Footer from "./Footer"
import image from "../../content/assets/TheClassyNurse.jpeg"
import Image from "react-bootstrap/Image"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let     header = (
    <div>
         <Link
           style={{
            boxShadow: `none`,
             color: `inherit`,
           }}
           to={`/`}
         >
           <Image src={image} alt="" fluid/>
         </Link>

    </div>
  )

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }} className="Site"
    >
        <NavBar/>

          <header>{header}</header>
          <main className="Site-content">{children}</main>

      <footer>
        © {new Date().getFullYear()}, We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.
      </footer>
    </div>

  )
}

export default Layout
