import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NavDropdown from "react-bootstrap/NavDropdown";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";

const NavBar =() => {
    return (
        <div>
            <Container>
            <Navbar className="NavBarCSS"
                    fixed="top"
                    style={{marginBottom:8,
                        textTransform: 'uppercase',
                        fontFamily: 'Montserrat, sans-serif',
                        fontsize:'11px',
                        fontweight: '400',
                        lineHeight: '15px',
                        letterSpacing:'2px'}}
                    bg="light"
                    variant="light"
                    expand="sm">
                {/*<Navbar.Brand href="#home">Logo</Navbar.Brand>*/}

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto" style={{padding:'0 20px'}}>
                      <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/Nursing%20School%20books%20I%20recommend/">Books</Nav.Link>
                        <Nav.Link href="/advice">Advice</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/disclaimer">Disclaimer</Nav.Link>
                        {/*<Nav.Link href="#home">Home</Nav.Link>*/}
                        {/*<Nav.Link href="#link">Contact</Nav.Link>*/}
                        {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                        {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                        {/*    <NavDropdown.Divider />*/}
                        {/*    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                        {/*</NavDropdown>*/}
                        {/*<Form inline>*/}
                        {/*    <FormControl type="text" placeholder="Search" className="mr-sm-2" />*/}
                        {/*    <Button variant="outline-secondary">Search</Button>*/}
                        {/*</Form>*/}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            </Container>
        </div>
    );
}

export default NavBar;